import {MongoPassport, PassportType} from '@travelfreedom/shared';
import {graphql, navigate, useStaticQuery} from 'gatsby';
import React from 'react';

import {buildMapDataFromRankingGradient, CountryPill, Layout, SEO, WorldMap} from '../components';
import {Knowledge, Passport} from '../library/common';
import {SingleRanking} from '../library/evaluation';

interface DocumentsProps {
  title: string;
  type: PassportType;
}

/** This is not really a template, but a component. */
export const DocumentsTemplate = ({title, type}: DocumentsProps): JSX.Element => {
  const {allMongodbTravelfreedomPassports} = useStaticQuery(graphql`
    query {
      allMongodbTravelfreedomPassports(sort: {fields: name}) {
        nodes {
          globalCode
          name
          adjective
          ranking {
            rank
          }
        }
      }
    }
  `);

  const passports: Passport.Data[] = allMongodbTravelfreedomPassports.nodes
    .map((node: MongoPassport) => Passport.fromLeanJson(node))
    .filter((d: Passport.Data) => d.type === type);

  const ranking: SingleRanking =
    type !== 'NATIONAL_ID'
      ? Object.assign({}, ...passports.map((p) => ({[p.globalCode]: p.ranking.rank})))
      : null;

  const goToCode = (isoCode: string): void => {
    const isoPath = Knowledge.isoCodeToPath(isoCode);
    if (passports.find((p) => p.code === isoCode && p.type === type)) {
      navigate(`/${Passport.getTypePath(type)}/${isoPath}`);
    }
  };

  return (
    <Layout>
      <SEO title={title} pathname={Passport.getTypePath(type)} />
      <h1 className="font-bold text-gray-500 mt-3 mb-2 max-w-7xl mx-auto">{title}</h1>

      <div className="bg-white my-2 max-w-7xl mx-auto">
        <WorldMap
          worldMapData={buildMapDataFromRankingGradient(passports, ranking)}
          onTerritoryClick={goToCode}
        />
      </div>

      <div className="my-2 max-w-7xl mx-auto">
        {passports.map((passport) => (
          <CountryPill
            key={passport.globalCode}
            name={passport.name}
            code={passport.code}
            href={Passport.hrefPath(passport)}
          />
        ))}
      </div>
    </Layout>
  );
};

export default DocumentsTemplate;
